import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const fetchedServices = [
          {
            title: "Employment service",
            description: "Click Here For More Details ...",
            icon: "bi-chat-square-text",
            imgSrc: "assets/img/Employment.png",
            delay: "700",
            text: "Our employment services connect job seekers with top employers, offering personalized job placement and career support. We help you find the right job opportunities to match your skills and aspirations.",
            services: [
              {
                title: "Om Nursing Job",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Employment/homenursing.png",
                delay: "500",
              },
              {
                title: " Watch Men Job",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Employment/watchmajob.png",
                delay: "500",
              },
              {
                title: "Home work",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Employment/housejob.png",
                delay: "500",
              },
              {
                title: "Tele calling",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Employment/Employment03.png",
                delay: "500",
              },
              {
                title: "Office work",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Employment/Employment05.png",
                delay: "500",
              },
              {
                title: "Higher Posts",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Employment/Employment01.png",
                delay: "500",
              },
            ],
          },
          {
            title: "Cinema & Casting",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/cinema/Cinema.png",
            delay: "500",
            text: "Our camera sales and service ensure you get the best equipment and expert maintenance for all your photography needs. Capture every moment with confidence using our top-quality cameras and support",
            services: [
              {
                title: "Artist",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/cinema/photo07.png",
                delay: "500",
              },
              {
                title: "Hero",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/cinema/hero.png",
                delay: "500",
              },
              {
                title: "heroine",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/cinema/femaleArtist.png",
                delay: "500",
              },
              {
                title: "Male",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/cinema/artist.png",
                delay: "500",
                types: [
                  {
                    title: "1-18 Years",
                    description: "Click Here For More Details ...",
                    icon: "bi-bounding-box-circles",
                    imgSrc: "assets/img/cinema/childmale.png",
                    delay: "500",
                  },
                  {
                    title: "Above 18 Years",
                    description: "Click Here For More Details ...",
                    icon: "bi-bounding-box-circles",
                    imgSrc: "assets/img/cinema/artist.png",
                    delay: "500",
                  },
                ],
              },
              {
                title: "Female",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/cinema/heroin.png",
                delay: "500",
                types: [
                  {
                    title: "1-18 Years",
                    description: "Click Here For More Details ...",
                    icon: "bi-bounding-box-circles",
                    imgSrc: "assets/img/cinema/childfemale.png",
                    delay: "500",
                  },
                  {
                    title: "Above 18 Years",
                    description: "Click Here For More Details ...",
                    icon: "bi-bounding-box-circles",
                    imgSrc: "assets/img/cinema/heroin.png",
                    delay: "500",
                  },
                ],
              },
              {
                title: "Technician",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/cinema/tech.png",
                delay: "500",
              },
            ],
          },
          {
            title: "Camera",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/camera.png",
            delay: "500",
            text: "Our camera sales and service ensure you get the best equipment and expert maintenance for all your photography needs. Capture every moment with confidence using our top-quality cameras and support",
            services: [
              {
                title: "Camera sales",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Camerasales.png",
                delay: "500",
              },
              {
                title: "Camera Services",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Cameraservices.png",
                delay: "500",
                types: [
                  {
                    title: "Adithya Cini Service",
                    description: "Click Here For More Details ...",
                    icon: "bi-bounding-box-circles",
                    imgSrc: "assets/img/camera.jpeg",
                    delay: "500",
                  },
                ],
              },
              {
                title: "Camera Rent",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/camera-rent.png",
                delay: "500",
              },
            ],
          },
          {
            title: "Sales and service",
            description: "Click Here For More Details ...",
            icon: "bi-activity",
            imgSrc: "assets/img/salesandservies.png",
            delay: "200",
            text: "Our sales and service division offers top-quality support for TVs, and mobiles sales and service",
            services: [
              {
                title: "TV Sales and Service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/tvsales.png",
                delay: "200",
              },
              {
                title: "Mobile Sales and service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/MobileSales.png",
                delay: "200",
              },
            ],
          },
          {
            title: "Vehicles",
            description: "Click Here For More Details ...",
            icon: "bi-broadcast",
            imgSrc: "assets/img/vehicles.png",
            delay: "300",
            text: "Our vehicle sales and service offer reliable support and top-quality maintenance for all your automotive needs. Whether you need a new vehicle or service for your current one, we've got you covered.",
            services: [
              {
                title: "Two wheeler sales and service",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/twoWeeler.png",
                delay: "300",
              },
              {
                title: "Four wheeler sales and service",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Fourwheeler.png",
                delay: "300",
              },
              {
                title: "Driving training and license assistance (RTO)",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/driving-licence-guide.png",
                delay: "300",
              },
            ],
          },
          {
            title: "Tours and Travels",
            description: "Click Here For More Details ...",
            icon: "bi-easel",
            imgSrc: "assets/img/toursandtravels.png",
            delay: "400",
            text: "Our tours and travels service provides exciting and well-organized trips to various destinations. Experience hassle-free travel planning and unforgettable adventures with us.",
            services: [
              {
                title: "Rented cars",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/tour/Car-Rental.png",
                delay: "400",
              },
              {
                title: "Tour Package",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/tour/tour.png",
                delay: "400",
              },
              {
                title: "Passport service",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/tour/passport.png",
                delay: "400",
              },
            ],
          },

          {
            title: "Household services",
            description: "Click Here For More Details ...",
            icon: "bi-calendar4-week",
            imgSrc: "assets/img/householdservices.png",
            delay: "600",
            text: "Our household services offer expert assistance for all your home maintenance and repair needs. Enjoy a well-maintained and comfortable home with our reliable services.",
            services: [
              {
                title: "Tank clean",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/tank-cleaning.png",
                delay: "600",
              },
              {
                title: "Plumbing",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/plumbing.png",
                delay: "600",
              },
              {
                title: "Electrical service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/electricalservice.png",
                delay: "600",
              },
              {
                title: "House Cleaning",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/housecleaning.png",
                delay: "600",
              },
              {
                title: "Carpentry",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/carpentry.png",
                delay: "600",
              },
              {
                title: "House shifting service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/houseshifting.png",
                delay: "600",
              },
              {
                title: "House Maid service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/housemaid.png",
                delay: "600",
              },
              {
                title: "Solar service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/houseHold/solarservices.png",
                delay: "600",
              },
            ],
          },
          {
            title: "Real Estate service",
            description: "Click Here For More Details ...",
            icon: "bi-chat-square-text",
            imgSrc: "assets/img/real-estate.png",
            delay: "700",
            text: "Our real estate service provides comprehensive support for buying, selling, and managing properties. Trust us for expert guidance and seamless transactions in all your real estate ventures.",
            services: [
              {
                title: "House / apartment / Villa rent / lease",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/realEstate/houseapartmentvillarent.png",
                delay: "700",
              },
              {
                title: "House / apartment / Villa sale / purchase",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/realEstate/houseapartmentSales.png",
                delay: "700",
              },
              {
                title: "Land / agricultural land / farm - rent / lease",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/realEstate/landagriculturalrent.png",
                delay: "700",
              },
              {
                title: "Land / agricultural land / farm - Sale / Purchase",
                description: "Click Here For More Details ...",
                icon: "bi-chat-square-text",
                imgSrc: "assets/img/realEstate/landagriculturalsale.png",
                delay: "700",
              },
            ],
          },
          {
            title: "Civil Construction",
            description: "Click Here For More Details ...",
            icon: "bi-activity",
            imgSrc: "assets/img/civilconstruction.png",
            delay: "200",
            text: "Our civil construction services deliver high-quality solutions for residential, commercial, and industrial projects. From planning to execution, we ensure reliable and durable construction results.",
            services: [
              {
                title: "Structural engineering service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc:
                  "assets/img/CivilConstruction/structuralengineering.png",
                delay: "200",
              },
              {
                title: "Architecture Design and planning",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/CivilConstruction/architecturedesign.png",
                delay: "200",
              },
              {
                title: "3D Walkthrough",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/CivilConstruction/3dwalkthrough.png",
                delay: "200",
              },
              {
                title: "Construction service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/CivilConstruction/constructionservice.png",
                delay: "200",
              },
              {
                title: "Construction Material supply",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc:
                  "assets/img/CivilConstruction/constructionmaterials.png",
                delay: "200",
              },
              {
                title: "Lift service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/CivilConstruction/liftservice.png",
                delay: "200",
              },
              {
                title: "Generator service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/CivilConstruction/generatorservice.png",
                delay: "200",
              },
              {
                title: "Escalator service",
                description: "Click Here For More Details ...",
                icon: "bi-activity",
                imgSrc: "assets/img/CivilConstruction/Escalator.png",
                delay: "200",
              },
            ],
          },
          {
            title: "Event management",
            description: "Click Here For More Details ...",
            icon: "bi-broadcast",
            imgSrc: "assets/img/event-management.png",
            delay: "300",
            tet: "Our event management services offer seamless planning and execution for weddings, corporate events, and more. We handle every detail to ensure your event is memorable and stress-free.",
            services: [
              {
                title: "Marriage event",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/marriageevent.png",
                delay: "300",
              },
              {
                title: "Pre wedding",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/prewedding.png",
                delay: "300",
              },
              {
                title: "Birthday / House warming etc",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/birthday.png",
                delay: "300",
              },
              {
                title: "Cooking Contract",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/cookingcontract.png",
                delay: "300",
              },
              {
                title: "Decoration contract",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/decorationcontract.png",
                delay: "300",
              },
              {
                title: "Infrastructure service for event",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/infrastructure.png",
                delay: "300",
              },
              {
                title: "Wedding hall (kalyana Mantapa) Rent",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/weddinghall.png",
                delay: "300",
              },
              {
                title: "Birthday hall for rent",
                description: "Click Here For More Details ...",
                icon: "bi-broadcast",
                imgSrc: "assets/img/Eventmanagement/birthdayhall.png",
                delay: "300",
              },
            ],
          },
          {
            title: "Finance",
            description: "Click Here For More Details ...",
            icon: "bi-easel",
            imgSrc: "assets/img/finance.png",
            delay: "400",
            text: "Our finance services provide expert advice and solutions for personal and business financial needs. From budgeting to investment strategies, we help you achieve financial success and security.",
            services: [
              {
                title: "Housing Loans",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/Finance/HOME_LOAN_.png",
                delay: "400",
              },
              {
                title: "Personal Loan",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/Finance/personaloan.png",
                delay: "400",
              },
              {
                title: "Business Loan",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/Finance/business-loan.png",
                delay: "400",
              },
            ],
          },
          {
            title: "Medical Service",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/medical.png",
            delay: "500",
            text: "Our medical services offer comprehensive healthcare solutions, including consultations, treatments, and emergency care. Trust us for reliable and professional medical support for all your health needs.",
            services: [
              {
                title: "Health care Specialist Service",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/healthcarespecialist.png",
                delay: "500",
              },
              {
                title: "Ayurvedic service",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Ayurvedic.png",
                delay: "500",
              },
              {
                title: "Acupuncture service",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/acupuncture.png",
                delay: "500",
              },
            ],
          },
          {
            title: "Matrimony Service",
            description: "Click Here For More Details ...",
            icon: "bi-calendar4-week",
            imgSrc: "assets/img/matrimony.png",
            delay: "600",
            text: "Our matrimony services help you find the perfect match with personalized matchmaking and support. We provide a trusted platform for meaningful connections and successful relationships.",
            services: [
              {
                title: "Bride",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Matrimony/5065586A.png",
                delay: "500",
              },
              {
                title: "Groom",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/Matrimony/couple-cartoon-wedding.png",
                delay: "500",
              },
            ],
          },

          {
            title: "Training and skill development",
            description: "Click Here For More Details ...",
            icon: "bi-bounding-box-circles",
            imgSrc: "assets/img/trainingiandskill.png",
            delay: "500",
            text: "Our training and skill development services provide specialized programs to enhance your professional abilities. We offer courses designed to boost your career prospects and personal growth.",
            services: [
              {
                title: "Cinema acting – Direction training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/cinimaacting.png",
                delay: "500",
              },
              {
                title: "VFX training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/vfx.png",
                delay: "500",
              },
              {
                title: "Editing training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/video-editing.png",
                delay: "500",
              },
              {
                title: "Color Grading Training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/colorgrading.png",
                delay: "500",
              },
              {
                title: "Cinematography",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/cinimatograghy.png",
                delay: "500",
              },
              {
                title: "Beautician Training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/beautician.png",
                delay: "500",
              },
              {
                title: "Animation Training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/Animation.png",
                delay: "500",
              },
              {
                title: "Web application design and development",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/webapplicationdesign.png",
                delay: "500",
              },
              {
                title: "Hardware and networking training",
                description: "Click Here For More Details ...",
                icon: "bi-bounding-box-circles",
                imgSrc: "assets/img/training/hardwareandnetworking.png",
                delay: "500",
              },
            ],
          },
          {
            title: "Government service",
            description: "Click Here For More Details ...",
            icon: "bi-calendar4-week",
            imgSrc: "assets/img/government.png",
            delay: "600",
            text: "Our government services assist with navigating various government processes, from documentation to applications. We provide expert guidance to ensure a smooth and efficient experience with government procedures.",
            services: [
              {
                title: "Ration card registration",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/rationcard.png",
                delay: "600",
              },
              {
                title: "Adhar / PAN service",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/aadhaarcard.png",
                delay: "600",
              },
              {
                title: "Other Govt certificate services",
                description: "Click Here For More Details ...",
                icon: "bi-calendar4-week",
                imgSrc: "assets/img/Govtcertificate.png",
                delay: "600",
              },
            ],
          },
          {
            title: "Hotel services including lodging / Rooms",
            description: "Click Here For More Details ...",
            icon: "bi-chat-square-text",
            imgSrc: "assets/img/householdservices.png",
            delay: "700",
            text: "Our hotel services offer comfortable lodging and well-equipped rooms for both short and long stays. Enjoy a relaxing and convenient experience with our top-notch accommodations and amenities.",
            services: [
              {
                title: "Hotels",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/Hotel/hotel-batiment.png",
                delay: "400",
              },
              {
                title: "Star Hotels",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/Hotel/hotel-beachbuilding.png",
                delay: "400",
              },
              {
                title: "Resorts",
                description: "Click Here For More Details ...",
                icon: "bi-easel",
                imgSrc: "assets/img/Hotel/lovepik-resort.png",
                delay: "400",
              },
            ],
          },
        ];
        if (fetchedServices.length === 0) {
          setNoData(true);
        } else {
          setServicesData(fetchedServices);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
        setNoData(true);
      } finally {
        setLoading(false);
      }
    };
    fetchServices();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (noData) {
    return <div>No Data Available</div>;
  }

  return (
    <section id="services" className="services section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Services</h2>
        <p>
          We offer a comprehensive suite of services catering to various needs,
          from vehicle rentals and tours to household and real estate services.
          Our offerings include specialized services in finance, medical care,
          event management, training, and government services, ensuring you
          receive expert assistance in every aspect of your life.
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-5">
          {servicesData?.map((service, index) => (
            <div
              key={index}
              className="col-xl-4 col-md-6"
              data-aos="zoom-in"
              data-aos-delay={service.delay}
            >
              <div className="service-item">
                <div className="img">
                  <img
                    src={service.imgSrc}
                    className="img-fluid"
                    alt={service.title}
                  />
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className={`bi ${service.icon}`}></i>
                  </div>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/service-list", {
                        state: {
                          services: service.services,
                          title: service.title,
                          text: service.text,
                        },
                      })
                    }
                    className="stretched-link"
                  >
                    <h3>{service.title}</h3>
                  </a>
                  <p
                    onClick={() =>
                      navigate("/service-list", {
                        state: {
                          services: service.services,
                          title: service.title,
                          text: service.text,
                        },
                      })
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
