import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ServicesList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(location.state?.title || "");
    const fetchedServices = location.state?.services || [];
    setTimeout(() => {
      setServices(fetchedServices);
      setLoading(false);
    }, 500);
  }, [location]);

  if (loading) {
    return (
      <section id="services" className="services section">
        <div className="container section-title" data-aos="fade-up">
          <h3>Loading...</h3>
        </div>
      </section>
    );
  }

  if (services.length === 0) {
    return (
      <section id="services" className="services section">
        <div className="container section-title" data-aos="fade-up">
          <h2>{title}</h2>
          <p>No data found</p>
        </div>
        <div className="container">
          <button
            onClick={() => navigate(-1)}
            className="btn btn-primary"
            style={{ backgroundColor: "#0EA2BD", border: "#0EA2BD" }}
          >
            Go Back
          </button>
        </div>
      </section>
    );
  }

  return (
    <section id="services" className="services section">
      <div className="container section-title" data-aos="fade-up">
        <h2>{title !== "" && title}</h2>
        <p>{location.state?.text}</p>
      </div>

      {/* Go Back Button */}
      <div className="container">
        <button
          onClick={() => navigate(-1)}
          className="btn btn-primary"
          style={{ backgroundColor: "#0EA2BD", border: "#0EA2BD" }}
        >
          Go Back
        </button>
      </div>

      {/* End Section Title */}
      <div
        className="container"
        data-aos="fade-up"
        data-aos-delay="100"
        style={{ marginTop: "2rem" }}
      >
        <div className="row gy-5">
          {services.map((service, index) => (
            <div
              key={index}
              className="col-xl-4 col-md-6"
              data-aos="zoom-in"
              data-aos-delay={service.delay}
            >
              <div className="service-item">
                <div className="img">
                  <img
                    src={service.imgSrc}
                    className="img-fluid"
                    alt={service.title}
                  />
                </div>
                <div className="details position-relative">
                  <div className="icon">
                    <i className={`bi ${service.icon}`}></i>
                  </div>
                  <a
                    onClick={() =>
                      navigate("/service-types", {
                        state: {
                          types: service.types,
                          title: service.title,
                          text: service.text,
                        },
                      })
                    }
                    className="stretched-link"
                  >
                    <h3>{service.title}</h3>
                  </a>
                  <p
                    onClick={() =>
                      navigate("/service-types", {
                        state: {
                          types: service.types,
                        },
                      })
                    }
                  >
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
